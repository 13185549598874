<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex class="mb-5">
        <v-card elevation="4" width="800" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color:gray"><b>رسالة جديدة</b></h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout row-wrap>
                {{receivers}}
                <v-flex xs12 md12>
                  <v-autocomplete
                    :items="users"
                    v-model="receivers"
                    :filter="customFilter"
                    chips
                    multiple
                    color="white"
                    item-text="item"
                    label="المستقبل"
                    item-value="value"
                    return-object
                    required
                    :rules="[v => !!v || 'المستقبل مطلوب']"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap row>
                <v-flex xs12 md12>
                  <v-text-field
                    :rules="[v => !!v || 'العنوان مطلوب']"
                    label="عنوان الإيميل"
                    placeholder="عنوان الإيميل"
                    v-model="subject"
                    
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap row>
                <v-flex xs12 md12>
                  <v-textarea
                    :rules="[v => !!v || 'نص الإيميل مطلوب']"
                    label="نص الإيميل"
                    v-model="body"
                    outlined
                    rows="10"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap row>
                <v-flex xs12 md12>
                  <v-file-input
                    type="file"
                    v-model="files"
                    multiple
                    label="المرفقات"
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="background:#58dd5b;color:white;margin-left:5px;"
              @click="sendMessage"
            >
              <b>إرسال</b>
            </v-btn>
            <v-btn
              style="background:#5867dd;color:white;margin-left:5px;"
              @click="reset"
            >
              <b>إعادة تعيين</b>
            </v-btn>
            <!--    <v-btn @click="$router.go(-1)" style="background:gray;" dark>
                            <b>رجوع</b>
                        </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Targets",
  data() {
    return {
      files: [],
      receivers: [],
      users: [],
      subject: "",
      body: "",
      valid: true
    };
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    reset() {
      this.$refs.form.reset();
      this.receivers = "";
      this.subject = "";
      this.body = "";
      this.files = [];
    },
    async sendMessage() {
      if (this.$refs.form.validate()) {
        var data = new FormData();
        let url = null;
        if (this.receivers.length > 1) {
          url = "Mail/sendmultiemails";
          for (let i = 0; i < this.receivers.length; i++) {
            let rec = this.receivers[i].item;
            data.append("ToEmails", rec);
          }
        } else {
          url = "Mail/sendemail";
          data.append("ToEmail", this.receivers[0].item);
        }
        data.append("Subject", this.subject);
        data.append("Body", this.body);
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          data.append("Attachments", file);
        }
        await this.ApiService.post(url, data)
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.reset();
            // this.$router.push({name:'dashboard'});
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    async getUsers() {
      this.users = [];
      await this.ApiService.get("auth/listusers")
        .then(res => {
          var allUsers = res.data;
          allUsers.forEach(el => {
            this.users.push({ value: el.user.id, item: el.user.email });
          });
        })
        .catch(() => {});
    }
  },

  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getUsers();
  }
};
</script>
